import { QueryObserverResult, RefetchOptions, useQuery } from "@tanstack/react-query";
import { TSaleContent } from "core/models/sales";
import { IPage } from "core/models/utils";
import DefaultModal from "../../defaultModal/defaultModal";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { TPaymentOption } from "core/models/sale";
import { Notification } from "app/components/toastNotification/toastNotification";
import { v4 as uuidv4 } from 'uuid'
import { formatBigNameWithSize, formatCurrencyBR } from "core/utils/globalFunctions";
import { AxiosError } from "axios";
import { SalesService } from "core/api/sales/salesService";
import theme from "core/theme/theme";
import PaymentModal from "./paymentModal";

interface IPayPromissorySaleModalProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    sale: TSaleContent;
    refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<IPage<any>, Error>>
}

type IPayment = {
    id: string;
    idPayment: number;
    payment: string;
    value: number;
    canEdit?: boolean;
    datePayment: string;
    status: string;
}


type ProductDTORequest = {
    productId: number;
    amount: number;
    productCharacteristcsDTOs: {
        id: number;
        amountProductCharacteristics: number;
    }[];
};

type PaymentMethodDTORequest = {
    id: number;
    value: number;
};

interface ProductCharacteristicDTO {
    id: number;
    amountProductCharacteristics: number;
    description: string;
}

interface ProductDTO {
    id: number;
    productId: number;
    amount: number;
    unitaryValue: number;
    name: string;
    productCharacteristicsId: number;
    productCharacteristcsDTOs: ProductCharacteristicDTO[];
}

interface SelectedProductsState {
    productDTOs: ProductDTO[];
}


const PayConsignedSale = (props: IPayPromissorySaleModalProps) => {
    const { isOpen, onClose, onOpen, sale, refetch } = props;
    const [paymentMethods, setPaymentMethods] = useState<TPaymentOption[]>([]);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);

    const [options, setOptions] = useState<string[]>([]);
    const [paymentList, setPaymentList] = useState<IPayment[]>([]);
    const [paymentValueModal, setPaymentValueModal] = useState(false);
    const [paymentValue, setPaymentValue] = useState<number | undefined>(0);
    const [selectedPayment, setSelectedPayment] = useState<string | undefined>();
    const [tempPayment, setTempPayment] = useState<IPayment | undefined>();
    const [loadingPayment, setLoadingPayment] = useState(false);
    const totalPayed = paymentList.reduce((acc, curr) => (acc + curr.value), 0);



    // get dos items da venda
    const salesItems = useQuery({
        queryKey: ['salesItemsConsigned', sale.saleId],
        queryFn: () => SalesService.getSaleDetails(String(sale.saleId)),
        staleTime: Infinity
    });

    const getPayments = useQuery({
        queryKey: ['salesPayments',],
        queryFn: () => SalesService.getListPaymentTypes(),
        staleTime: Infinity
    })

    // guarda os produtos selecionados
    const [selectedProducts, setSelectedProducts] = useState<SelectedProductsState>({
        productDTOs: [],
    });

    const totalValue = useMemo(() => {
        return selectedProducts.productDTOs.reduce((acc, curr) => (acc + curr.amount * curr.unitaryValue) - sale.discount, 0);
    }, [selectedProducts]);


    // guarda os produtos iniciais da lista
    const [fetchedProducts, setFetchedProducts] = useState<ProductDTO[]>([]);


    useEffect(() => {
        console.log(salesItems);
    }, [salesItems]);


    // salva os itens da venda e salva nos states
    useEffect(() => {
        if (salesItems.isSuccess && salesItems.data) {
            const items = salesItems.data.data.data.items.map((item: any) => {
                const characteristic = item?.product?.productCharacteristics?.find(
                    (char: any) => char.id === item.productCharacteristics.id
                );
                return {
                    id: item.id,
                    productId: item.product.id,
                    name: item.product.name,
                    productCharacteristicsId: item?.productCharacteristics?.id || undefined,
                    amount: item.amount,
                    unitaryValue: item.unitaryValue,
                    productCharacteristcsDTOs: characteristic
                        ? [
                            {
                                id: characteristic.id,
                                amountProductCharacteristics: item.amount,
                                description: characteristic.description,
                            },
                        ]
                        : [],
                };
            });

            setFetchedProducts(items);
            setSelectedProducts({ productDTOs: items });
        }
    }, [salesItems.isSuccess, salesItems.data, isOpen]);

    // salva os tipos de pagamento
    useEffect(() => {
        if (getPayments.isSuccess && getPayments.data) {
            const paymentOptions = getPayments.data.data.data.content;
            setPaymentMethods(paymentOptions);
            const filteredOptions = paymentOptions
                .filter((item: any) => item.name !== "PROMISSÓRIA" && item.name !== "CONSIGNADO")
                .map((item: any) => item.name);
            setOptions(filteredOptions);
        }
    }, [getPayments.isSuccess,
    getPayments.data,
        isOpen])

    const handleFocus = (event: any) => {
        event.target.select();
    };



    // marca ou desmarca um produto
    const handleSelectItem = (item: ProductDTO, isSelected: boolean) => {
        if (isSelected) {
            setSelectedProducts((prevState) => ({
                productDTOs: [...prevState.productDTOs, item],
            }));
        } else {
            setSelectedProducts((prevState) => ({
                productDTOs: prevState.productDTOs.filter((product) => product.id !== item.id),
            }));
        }
    };

    // altera a quantidade de um produto
    const handleQuantityChange = (itemId: number, newAmount: number) => {
        const originalProduct = fetchedProducts.find((item) => item.id === itemId);
        if (originalProduct) {
            const maxAmount = originalProduct.amount;
            const updatedAmount = Math.min(newAmount, maxAmount);

            setSelectedProducts((prevState) => {
                const updatedProducts = prevState.productDTOs.map((product) => {
                    if (product.id === itemId) {
                        const updatedProduct = {
                            ...product,
                            amount: updatedAmount,
                        };

                        if (product.productCharacteristcsDTOs.length > 0) {
                            updatedProduct.productCharacteristcsDTOs[0].amountProductCharacteristics = updatedAmount;
                        }

                        return updatedProduct;
                    }
                    return product;
                });

                // se a quantidade for 0, remove o produto da lista
                const filteredProducts = updatedAmount === 0
                    ? updatedProducts.filter((product) => product.id !== itemId)
                    : updatedProducts;

                return { productDTOs: filteredProducts };
            });
        }
    };


    const handlePaySale = () => {
        setLoadingPayment(true);

        // Verifica os produtos não selecionados, ou seja os que vão ser devolvidos
        const unselectedProducts = fetchedProducts.filter(
            (product) => !selectedProducts.productDTOs.some((selected) => selected.id === product.id)
        );

        // Verifica produtos selecionados, porem que a quantidade selecionada é menor que a quantidade original
        // ou seja, produtos que uma parte será devolvida e outra paga
        const productsWithReducedQuantity = selectedProducts.productDTOs
            .filter((selected) => {
                const originalProduct = fetchedProducts.find((product) => product.id === selected.id);
                return originalProduct && selected.amount < originalProduct.amount;
            })
            .map((selected) => {
                const originalProduct = fetchedProducts.find((product) => product.id === selected.id);
                return originalProduct
                    ? {
                        productId: selected.productId,
                        amount: originalProduct.amount - selected.amount,
                        productCharacteristcsDTOs: selected.productCharacteristcsDTOs.map((char) => ({
                            id: char.id,
                            amountProductCharacteristics: originalProduct.amount - selected.amount,
                        })),
                    }
                    : selected;
            });

        // Combinando os produtos não selecionados e os produtos com quantidade reduzida
        // Combinando os produtos não selecionados e os produtos com quantidade reduzida
        const combinedProducts = [...unselectedProducts, ...productsWithReducedQuantity];

        // Mapeando os produtos para combinar aqueles com o mesmo productId
        const productMap = new Map<number, ProductDTORequest>();

        combinedProducts.forEach((product) => {
            if (productMap.has(product.productId)) {
                const existingProduct = productMap.get(product.productId);

                if (existingProduct) {
                    // Soma o amount dos produtos com o mesmo productId
                    existingProduct.amount += product.amount;

                    // Combina as características sem alteração
                    existingProduct.productCharacteristcsDTOs = [
                        ...existingProduct.productCharacteristcsDTOs,
                        ...product.productCharacteristcsDTOs
                    ];
                }
            } else {
                // Adiciona o novo produto ao map
                productMap.set(product.productId, { ...product });
            }
        });

        // Transformando o map em um array de ProductDTORequest
        const productDTOs: ProductDTORequest[] = Array.from(productMap.values());



        // lista de pagamentos
        const paymentMethodDTOList: PaymentMethodDTORequest[] = paymentList.map((payment) => ({
            id: payment.idPayment,
            value: payment.value,
        }));

        //teste
        const requestPayload = {
            productDTOs: productDTOs.length > 0 ? productDTOs : [],
            paymentMethodDTOList,
        };


        SalesService.payConsignedSale(sale.saleId, requestPayload.productDTOs, paymentMethodDTOList)
            .then((resp) => {
                handleCloseModal();
                setLoadingPayment(false);
                refetch();
            })
            .catch((err: AxiosError) => {
                setLoadingPayment(false);
                Notification(String(err.message), "error");
            });
    };


    // ! função pra pegar o id do pagamento pelo nome
    const getIdByPaymentName = (payment: string | undefined) => {
        switch (payment) {
            case "DINHEIRO":
                return 5;
            case "PIX":
                return 1;
            case "BOLETO":
                return 2;
            case "CREDITO":
                return 7;
            case "DÉBITO":
                return 6;

            default:
                return undefined;
        }
    };

    // ! função pra adicionar o pagamento, e verificar se já existe
    const handleConfirmPayment = () => {
        const isDuplicatePayment = paymentList.some(payment => payment.payment === selectedPayment);
        if (isDuplicatePayment) {
            Notification("Método de pagamento já adicionado.", "warning");
            return;
        }

        const newPayment: IPayment = {
            id: uuidv4(),
            idPayment: getIdByPaymentName(selectedPayment) || 0,
            payment: selectedPayment || "",
            value: paymentValue || 0,
            canEdit: true,
            datePayment: new Date().toISOString(),
            status: "Em andamento",
        };
        setPaymentList([...paymentList, newPayment]);
        setPaymentValue(undefined);
        setPaymentValueModal(false);
    };

    // ! função pra deletar o pagamento
    const handleDeletePayment = (id: string) => {
        setPaymentList(paymentList.filter((item) => item.id !== id));
    };

    // ! função pra editar o pagamento
    const handleEditPayment = () => {
        const newPayment: IPayment = {
            id: tempPayment?.id!!,
            idPayment: getIdByPaymentName(tempPayment?.payment!!) || 0,
            payment: tempPayment?.payment!! || "",
            value: paymentValue || 0,
            canEdit: true,
            datePayment: new Date().toISOString(),
            status: "Em andamento",
        };
        setPaymentList(paymentList.map((item) => item.id === tempPayment?.id ? newPayment : item));
        setPaymentValue(undefined);
        setPaymentValueModal(false);
        setTempPayment(undefined);
    };

    // ! função pra fechar o modal de pagamento
    const handleClosePaymentModal = () => {
        setPaymentValueModal(false);
        setPaymentValue(undefined);
        setTempPayment(undefined);
        setSelectedPayment(undefined);
    };

    // ! função pra fechar o modal principal
    const handleCloseModal = () => {
        setOpenPaymentModal(false)
        onClose();
        setPaymentList([]);
        setPaymentValue(undefined);
        setTempPayment(undefined);
        setSelectedPayment(undefined);
        setSelectedProducts({ productDTOs: [] });
        setFetchedProducts([]);
    };

    return (

        <DefaultModal
            title={openPaymentModal ? "Pagamento" : "Escolha os itens"}
            isOpen={isOpen}
            onClose={handleCloseModal}
            onOpen={onOpen}
        >
            {salesItems.isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        height: "100%",
                        minWidth: "500px",
                        minHeight: "300px",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : openPaymentModal ? (
                <PaymentModal
                    sale={sale}
                    paymentMethods={paymentMethods}
                    paymentList={paymentList}
                    paymentValue={paymentValue}
                    tempPayment={tempPayment}
                    loading={getPayments.isLoading}
                    paying={loadingPayment}
                    totalPayed={totalPayed}
                    paymentValueModal={paymentValueModal}
                    selectedPayment={selectedPayment}
                    options={options}
                    handlePaySale={handlePaySale}
                    handleConfirmPayment={handleConfirmPayment}
                    handleDeletePayment={handleDeletePayment}
                    handleEditPayment={handleEditPayment}
                    handleCloseModal={() => setOpenPaymentModal(false)}
                    handleClosePaymentModal={handleClosePaymentModal}
                    setPaymentValueModal={setPaymentValueModal}
                    setSelectedPayment={setSelectedPayment}
                    setPaymentValue={setPaymentValue}
                    setTempPayment={setTempPayment}
                    totalValue={totalValue}
                    type="consigned"
                />
            ) : (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, minWidth: "500px", paddingBottom: "0.4rem" }}>
                    {salesItems.isLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                height: "100%",
                                minWidth: "500px",
                                minHeight: "300px",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ overflowY: "scroll", maxHeight: 400 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Produto</TableCell>
                                        <TableCell>Característica</TableCell>
                                        <TableCell>Preço unitário</TableCell>
                                        <TableCell>Preço total</TableCell>
                                        <TableCell>Quantidade</TableCell>
                                        <TableCell>Selecionar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fetchedProducts.map((item) => {
                                        const isSelected = !!selectedProducts.productDTOs.find((i) => i.id === item.id);
                                        return (
                                            <TableRow key={item.id}>
                                                <Tooltip title={item.name}>
                                                    <TableCell>{formatBigNameWithSize(item.name, 15)}</TableCell>
                                                </Tooltip>
                                                <TableCell>
                                                    {item.productCharacteristcsDTOs[0]?.description ?? "--"}
                                                </TableCell>
                                                <TableCell>{formatCurrencyBR(item.unitaryValue)}</TableCell>
                                                <TableCell>
                                                    {formatCurrencyBR(
                                                        isSelected ? item.unitaryValue * (selectedProducts.productDTOs.find((i) => i.id === item.id)?.amount ?? 0) : 0
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <TextField
                                                        type="number"
                                                        value={isSelected ? selectedProducts.productDTOs.find((i) => i.id === item.id)?.amount ?? 0 : 0}
                                                        onChange={(event) => handleQuantityChange(item.id, Number(event.target.value))}
                                                        disabled={!isSelected}
                                                        inputProps={{ min: 0, max: item.amount, style: { textAlign: "center" }, onFocus: handleFocus, onClick: handleFocus }}
                                                        size="small"
                                                        variant="standard"

                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        checked={isSelected}
                                                        onChange={(event) => handleSelectItem(item, event.target.checked)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "0.3rem", }}>
                        <Box>
                            {sale.discount > 0 &&
                                <Typography sx={{ fontSize: 15, fontWeight: "bold", marginLeft: "1rem", color: theme.COLORS.RED }}>
                                    Desconto: {formatCurrencyBR(sale.discount)}
                                </Typography>

                            }
                            <Typography sx={{ fontSize: 15, fontWeight: "bold", marginLeft: "1rem", color: theme.COLORS.GREEN1 }}>
                                Valor: {formatCurrencyBR(totalValue)}
                            </Typography>
                        </Box>

                        <Button
                            sx={{ marginRight: "1rem" }}
                            disabled={selectedProducts.productDTOs.length === 0}
                            onClick={() => setOpenPaymentModal(true)}
                        >
                            Continuar
                        </Button>
                    </Box>
                </Box>

            )}
        </DefaultModal>
    );
};

export default PayConsignedSale;