import { Box, CircularProgress, Paper, TableContainer } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import DateRangeDisplay from 'app/components/dateRange/dateRangeDisplay/dateRangeDisplay'
import { dashboardService } from 'core/api/dashboard/dashboardService'
import theme from 'core/theme/theme'
import { formatDateToSearch, getMonthName } from 'core/utils/globalFunctions'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import Chart from 'react-google-charts'
import styled from 'styled-components'

export default function ListSalesMonth() {
    // Estado do intervalo de data
    const [initDate, setInitDate] = useState<Dayjs | null | undefined>(
        dayjs().startOf('year')
    )
    const [finalDate, setFinalDate] = useState<Dayjs | null | undefined>(
        dayjs().endOf('year')
    )

    const salesDashboard = useQuery({
        queryKey: ['sales', initDate, finalDate],
        staleTime: Infinity,
        queryFn: () =>
            dashboardService.getMonthSales(
                formatDateToSearch(initDate!),
                formatDateToSearch(finalDate!)
            )
    })

    const salesData = salesDashboard.data || []

    return (
        <TableContainer
            sx={{ overflow: 'hidden', padding: 1 }}
            component={Paper}
        >
            <StyledBox>
                <StyledTypography>Período</StyledTypography>
                <DateRangeDisplay
                    typeOfDatePicker={'month'}
                    initialDate={initDate}
                    endDate={finalDate}
                    setInitialDate={setInitDate}
                    setFinalDate={setFinalDate}
                />
            </StyledBox>
            <Box>
                {salesDashboard.isLoading ? (
                    <LoadingWrapper>
                        <CircularProgress />
                    </LoadingWrapper>
                ) : salesDashboard.isError || !salesData.length ? (
                    <NoDataMessage>
                        {salesDashboard.isError
                            ? 'Erro ao carregar os dados.'
                            : 'Nenhuma informação encontrada para o período selecionado.'}
                    </NoDataMessage>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1
                        }}
                    >
                        {/* Gráfico do Total de Vendas */}
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="280px"
                            data={[
                                ['Mês', 'Total de Vendas'],
                                ...salesData.map((item: any) => [
                                    `${getMonthName(item.mes)}`,
                                    item.total_vendas
                                ])
                            ]}
                            options={{
                                title: 'Total de Vendas por Mês',
                                hAxis: { title: 'Mês' },
                                vAxis: { title: 'Total de Vendas' },
                                colors: ['#f3b02a'],
                                bar: { groupWidth: '50%' }
                            }}
                        />

                        {/* Gráfico do Montante Total */}
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="380px"
                            data={[
                                ['Mês', 'Montante Total (R$)'],
                                ...salesData.map((item: any) => [
                                    `${getMonthName(item.mes)}`,
                                    item.total_amount
                                ])
                            ]}
                            options={{
                                title: 'Montante Total por Mês',
                                hAxis: { title: 'Mês' },
                                vAxis: { title: 'Montante Total (R$)' },
                                colors: ['#ff7043'],
                                bar: { groupWidth: '50%' }
                            }}
                        />
                    </Box>
                )}
            </Box>
        </TableContainer>
    )
}

const StyledBox = styled(Box)`
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 0 0 0.5rem !important;
    border: solid 2px ${theme.COLORS.YELLOW2} !important;
`

const StyledTypography = styled.div`
    color: ${theme.COLORS.YELLOW2} !important;
    font-weight: bold !important;
    font-size: 15px !important;
    text-align: center !important;
`

const LoadingWrapper = styled.div`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 300px !important;
`

const NoDataMessage = styled.div`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 300px !important;
    font-size: 15px !important;
    font-weight: bold !important;
    color: ${theme.COLORS.YELLOW2} !important;
`
