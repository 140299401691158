import { Box } from '@mui/material'
import SellerSalesByMonth from './sellersSalesByMonth'
import ListSalesMonth from './listSalesMonth'
import SalesStatus from './salesStatus'
export default function Dashboard() {
    return (
        <Box
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <Box sx={{ flex: 5 }}>
                    <SellerSalesByMonth />
                </Box>
                <Box sx={{ flex: 2 }}>
                    <SalesStatus />
                </Box>
            </Box>

            <ListSalesMonth />
        </Box>
    )
}
