import { Dispatch, SetStateAction, useState } from 'react'
import { Display } from './styles'
import { Tooltip } from '@mui/material'
import { Dayjs } from 'dayjs'
import RangeDataPicker from 'app/components/dataPicker/rangeDatePicler'

interface IDisplayProps {
    initialDate: Dayjs | null | undefined
    endDate: Dayjs | null | undefined
    setInitialDate: Dispatch<SetStateAction<Dayjs | null | undefined>>
    setFinalDate: Dispatch<SetStateAction<Dayjs | null | undefined>>
    typeOfDatePicker: string
}

export default function DateRangeDisplay(props: Readonly<IDisplayProps>) {
    const {
        initialDate,
        endDate,
        setFinalDate,
        setInitialDate,
        typeOfDatePicker
    } = props

    const [datePickerState, setDatePickerState] = useState<boolean>(false)

    return (
        <>
            <Tooltip
                onClick={() => setDatePickerState(true)}
                title="Alterar data"
                followCursor
            >
                <Display>
                    {initialDate?.format('DD-MM-YYYY')}
                    {endDate ? ` - ${endDate?.format('DD-MM-YYYY')}` : ''}
                </Display>
            </Tooltip>
            <RangeDataPicker
                typeOfDatePicker={typeOfDatePicker}
                title="Filtrar data"
                isOpen={datePickerState}
                onOpen={() => setDatePickerState(true)}
                onClose={() => setDatePickerState(false)}
                initialDate={initialDate}
                endDate={endDate}
                setFinalDate={setFinalDate}
                setInitialDate={setInitialDate}
            />
        </>
    )
}
