import Brand from 'app/views/brand/brand'
import Category from 'app/views/category/category'
import Client from 'app/views/client/client'
import Collaborator from 'app/views/colaborator/collaborator'
import Collection from 'app/views/collection/collection'
import EditClientModal from 'app/views/editers/editClient'
import EditCollaboratorModal from 'app/views/editers/editCollaborator'
import EditIndicationModal from 'app/views/editers/editIndication'
import EditProduct from 'app/views/editers/editProduct'
import EditSupplierModal from 'app/views/editers/editSupplier'
import Indication from 'app/views/indication/indication'
import ForgotPassword from 'app/views/login/forgotPassword/ForgotPassword'
import Login from 'app/views/login/login'
import RedefinePassword from 'app/views/login/redefinePassword/redefinePassword'
import Product from 'app/views/product/product'
import RegisterBrand from 'app/views/registers/registerBrand'
import RegisterCategory from 'app/views/registers/registerCategory'
import RegisterClient from 'app/views/registers/registerClient'
import RegisterCollaborator from 'app/views/registers/registerCollaborator'
import RegisterCollection from 'app/views/registers/registerCollection'
import RegisterIndication from 'app/views/registers/registerIndication'
import RegisterSupplier from 'app/views/registers/registerSupplier'
import { Sale } from 'app/views/sale/sale'
import Supplier from 'app/views/supplier/supplier'
import DefaultRoute from 'core/utils/defaultRoute'
import ProtectedRoute from 'core/utils/protectedRoute'
import { Navigate, Route, Routes } from 'react-router-dom'
import Sales from '../src/app/views/sales/sales'
import SelectFilial from 'app/views/login/selectFilial/selectFilial'
import ProtectLogin from 'core/utils/protectLogin'
import Dashboard from 'app/views/dashboard/dashboard'

function App() {
    return (
        <Routes>
            <Route element={<DefaultRoute />}>
                <Route path="/selecionarFilial" element={<SelectFilial />} />
            </Route>

            <Route element={<ProtectLogin />}>
                <Route path="/login" element={<Login />} />
                <Route path="/esqueceuSenha" element={<ForgotPassword />} />
                <Route
                    path="/recuperacao-senha"
                    element={<RedefinePassword />}
                />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/clientes" element={<Client />} />
                <Route path="/registrarCliente" element={<RegisterClient />} />
                <Route path="/editarCliente" element={<EditClientModal />} />

                <Route path="/fornecedores" element={<Supplier />} />
                <Route
                    path="/registrarFornecedor"
                    element={<RegisterSupplier />}
                />
                <Route
                    path="/editarFornecedor"
                    element={<EditSupplierModal />}
                />

                <Route path="/indicacoes" element={<Indication />} />
                <Route
                    path="/registrarIndicacao"
                    element={<RegisterIndication />}
                />
                <Route
                    path="/editarIndicacao"
                    element={<EditIndicationModal />}
                />

                <Route path="/categorias" element={<Category />} />
                <Route
                    path="/registrarCategoria"
                    element={<RegisterCategory />}
                />

                <Route path="/marcas" element={<Brand />} />
                <Route path="/registrarMarca" element={<RegisterBrand />} />

                <Route path="/colecoes" element={<Collection />} />
                <Route
                    path="/registrarColecao"
                    element={<RegisterCollection />}
                />

                <Route path="/produtos" element={<Product />} />
                <Route path="/editarProduto" element={<EditProduct />} />

                <Route path="/colaboradores" element={<Collaborator />} />
                <Route
                    path="/registrarColaborador"
                    element={<RegisterCollaborator />}
                />
                <Route
                    path="/editarColaborador"
                    element={<EditCollaboratorModal />}
                />

                <Route path="/vendas" element={<Sales />} />

                <Route path="/venda" element={<Sale />} />
            </Route>

            <Route path="*" element={<Navigate to="/selecionarFilial" />} />
        </Routes>
    )
}

export default App
