import {
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    TableCell,
    IconButton,
    Tooltip,
    CircularProgress,
    Button,
    Modal
} from '@mui/material'
import DefaultModal from '../../defaultModal/defaultModal'
import {
    capitalizeFirstLetter,
    formatBigNameWithSize,
    formatCurrencyBR,
    formatDateBr
} from 'core/utils/globalFunctions'
import theme from 'core/theme/theme'
import { useEffect, useState } from 'react'
import DataTablePagination from 'app/components/table/pagination/pagination'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { IInfoProps, InfosComponent } from '../ClientDetailsModal'
import { SectionCol } from 'core/theme/globalStyles'
import { CupomFiscal } from 'app/components/cupomFiscal/CupomFiscal'
import { SalesService } from 'core/api/sales/salesService'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90svh',
    overflowY: 'scroll'
};

interface ISalesDetailsProps {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
    sale: any
}

interface IPaymentMethods {
    payment: string
    value: number
}

const SalesDetailsModal = (props: ISalesDetailsProps) => {
    const { isOpen, onClose, onOpen, sale } = props

    const [itemsRowsPerPage, setItemsRowsPerPage] = useState(10)
    const [itemsPage, setItemsPage] = useState(0)
    const [itemsCount, setItemsCount] = useState(0)

    const [showCupom, setShowCupom] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethods[]>([])
    const [tempItems, setTempItems] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [date, setDate] = useState<string | undefined>()

    const saleInfo: IInfoProps[] = [
        {
            key: 'Valor',
            value: sale.amount,
            formater: formatCurrencyBR
        },
        {
            key: 'Desconto',
            value: sale.discount,
            formater: formatCurrencyBR
        },
        {
            key: 'Troco',
            value: sale.changeValue,
            formater: formatCurrencyBR
        },
        {
            key: 'Data',
            value: sale.data,
            formater: formatDateBr
        },
        {
            key: 'Tipo',
            value: sale.type,
            formater: capitalizeFirstLetter
        },
        {
            key: 'status',
            value: sale.status,
            formater: capitalizeFirstLetter
        },
        {
            key: 'Mtd. de Pagamento',
            value: sale.paymentMethod
        }
    ]
    useEffect(() => {
        if (sale.saleId) {
            setIsLoading(true)
            SalesService.getSaleDetails(String(sale.saleId))
                .then((response) => {

                    const newPaymentList =
                        response.data.data.salesPaymentMethods.map(
                            (payment: any) => ({
                                payment: payment.paymentMethod.name,
                                value: payment.value
                            })
                        )
                    const itemsSale = response.data.data.items.map(
                        (item: any) => ({
                            productId: item.product.id,
                            sku: item.product.sku,
                            productName: item.product.name,
                            characteristicsDescription:
                                item.productCharacteristics ? item.productCharacteristics.description : "",
                            itemSalesAmount: item.amount,
                            unitaryValue:
                                sale.type === 'VAREJO'
                                    ? item.product.priceTag
                                    : item.product.resalePrice
                        })
                    )
                    const date = response.data.data.date
                    setDate(date)
                    setPaymentMethods(newPaymentList)
                    setTempItems(itemsSale)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados: ', error)
                    setIsLoading(false)
                })
        }
    }, [sale, isOpen])

    useEffect(() => {
        if (tempItems) {
            setItemsCount(tempItems.length)
        }
    }, [tempItems])

    return (
        <>
            <DataTablePagination
                setPage={setItemsPage}
                page={itemsPage}
                setRowsPerPage={setItemsRowsPerPage}
                rowsPerPage={itemsRowsPerPage}
                count={itemsCount}
            />
            <Modal
                open={showCupom}
                onClose={() => setShowCupom(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CupomFiscal
                        newSale={sale}
                        items={tempItems}
                        dateSale={date}
                        paymentMethods={paymentMethods}
                    />
                </Box>
            </Modal>

            <DefaultModal
                title="Detalhes da venda"
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                children={
                    < SectionCol sx={{ minWidth: '400px' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: 0, left: 20 }}
                            onClick={() => {
                                setShowCupom(true)
                            }}
                        >
                            <Tooltip title="Vizualizar cupom">
                                <ReceiptIcon
                                    sx={{ color: theme.COLORS.YELLOW }}
                                />
                            </Tooltip>
                        </IconButton>
                        <InfosComponent
                            style={{ marginTop: '40px' }}
                            items={[
                                {
                                    title: 'Informações da venda',
                                    infos: saleInfo
                                }
                            ]}
                        />
                        <Typography
                            sx={{ fontWeight: 'bold', textAlign: 'center' }}
                        >
                            Itens da venda
                        </Typography>
                        <Box
                            sx={{
                                maxHeight: 270,
                                overflowY: 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="left">Nome</TableCell>
                                        <TableCell align="left">
                                            Descrição
                                        </TableCell>
                                        <TableCell align="right">Qtd</TableCell>
                                        <TableCell align="right">
                                            Valor Unit.
                                        </TableCell>
                                        <TableCell align="right">
                                            Valor total
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                align="center"
                                            >
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : !tempItems ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                align="center"
                                            >
                                                Nenhum item encontrado
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        tempItems.map((row: any) => (
                                            <TableRow key={row.productId}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.productId}
                                                </TableCell>
                                                <Tooltip title={row.productName}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {formatBigNameWithSize(row.productName, 15)}
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell align="center">
                                                    {
                                                        row.characteristicsDescription || "--"
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.itemSalesAmount}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {formatCurrencyBR(
                                                        row.unitaryValue
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {formatCurrencyBR(
                                                        row.itemSalesAmount *
                                                        row.unitaryValue
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </SectionCol >
                }
            />
        </>
    )
}

export default SalesDetailsModal
